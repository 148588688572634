import { createSlice } from '@reduxjs/toolkit';

export default createSlice({
  name: 'navigation',
  initialState: {
    selectedTab: null,
  },
  reducers: {
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
  },
});

export const navigationProps = (state) => state;
