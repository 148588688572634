import { ORDER_REJECTION_REASONS } from '../../../../../../services/exports/Constants';

export default {
  titles: {
    step_1: 'Are you sure you want to reject the order?',
    step_2: 'Are you sure you want to reject the order?',
  },
  labels: {
    table: 'Table {{table}}',
    order: 'Order',
  },
  subtitle: 'If there’s a minor issue with the order, please first talk to the customer to resolve it.',
  reasons: {
    [ORDER_REJECTION_REASONS.ClosingSoon]: {
      name: 'Closing soon',
      description: 'Unfortunately, we don’t have time to make your order before closing time.',
    },
    [ORDER_REJECTION_REASONS.ItemUnavailable]: {
      name: 'Item unavailable',
      description: 'Unfortunately, we cannot currently offer the item(s) your ordered',
    },
    [ORDER_REJECTION_REASONS.CustomerMistake]: {
      name: 'Customer mistake',
      description: 'Seems like there was a miscommunication.',
    },
  },
  buttons: {
    reject: 'Reject Order',
    back: 'Back',
    no_cancel: 'No, Cancel',
    yes_reject: 'Yes, Reject',
  },
};
