import moment from 'moment-timezone';
import ReduxHooks from '../../store/ReduxHooks';
import { actionCreators } from '../../store/actions';
import { EventHelperTypes } from './Constants';

class HelperMethods {
  triggerEventSideEffects = (eventAction) => {
    ReduxHooks.dispatch(actionCreators.eventHelper.setEvent(eventAction));
  };

  resetEventSideEffects = () => {
    ReduxHooks.dispatch(actionCreators.eventHelper.resetEvent());
  };

  getCdnPath(path) {
    return `${import.meta.env.VITE_CDN_BASE_URL}${path}`;
  }

  formatCurrency(amount, currency = 'EUR', locale = null) {
    try {
      return new Intl.NumberFormat(locale ?? this.guessLocaleFromCurrency(currency), {
        style: 'currency',
        currency,
      }).format(amount);
    } catch (error) {
      return amount;
    }
  }

  guessLocaleFromCurrency(currency) {
    return {
      EUR: 'de-DE',
      USD: 'en-US',
      GBP: 'en-UK',
    }[currency] ?? 'de-DE';
  }

  formatDate(date, format, language) {
    try {
      let localDate = moment(date);

      if (language) {
        localDate = localDate.locale(language);
      }

      return localDate.format(format ?? 'MMMM Do YYYY');
    } catch (error) {
      return '';
    }
  }

  triggerLogoutEvent = () => {
    this.triggerEventSideEffects({
      type: EventHelperTypes.LOG_OUT,
    });
  };

  displayConfirmationModal(payload) {
    this.triggerEventSideEffects({
      type: EventHelperTypes.DISPLAY_CONFIRMATION_MODAL,
      payload,
    });
  }

  hideConfirmationModal() {
    this.triggerEventSideEffects({
      type: EventHelperTypes.DISPLAY_CONFIRMATION_MODAL,
      payload: null,
    });
  }
}

export default new HelperMethods();
