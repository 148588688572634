export default {
  headers: {
    table_number: 'Tisch #',
    last_edited: 'Letzte Bestellung',
    closing_time: 'Verlassen Zeit',
    status: 'Status',
    details: 'Details',
  },
  status: {
    paid: 'Bezahlt',
    refunded: 'Rückerstattet',
  },
  empty_list: 'Derzeit keine abgeschlossenen Tische',
};
