export default {
  headers: {
    table_number: 'Tisch #',
    last_edited: 'Letzte Bestellung',
    left_to_pay: 'Noch zu zahlen',
    total: 'Gesamt',
    details: 'Details',
  },
  empty_list: 'Derzeit keine offenen Tische',
};
