import { DeliveryProvider, ORDER_METHODS } from '../../../../../../services/exports/Constants';

export default {
  text: {
    [ORDER_METHODS.pickup]: 'Hat der Kunde die Bestellung bereits abgeholt?',
    [ORDER_METHODS.room_service]: 'Hat der Kunde die Bestellung bereits abgeholt?',
    [ORDER_METHODS.delivery]: {
      [DeliveryProvider.Standalone]: 'Hast du die Bestellung bereits ausgeliefert?',
      [DeliveryProvider.FirstDelivery]: 'Hat der Fahrer die Bestellung bereits abgeholt?',
    },
  },
  buttons: {
    cancel: 'Nein, Abbrechen',
    confirm: 'Ja',
  },
};
