import React from 'react';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';
import BellIcon from '../../resources/assets/icons/BellIcon';
import CustomButton from '../../components/buttons/CustomButton';
import { ROUTES } from '../../services/exports/Constants';

export default function ErrorView() {
  const { t } = useTranslation(undefined, { keyPrefix: 'Views:Error' });

  // eslint-disable-next-line no-return-assign
  const navigateHome = () => window.location.href = ROUTES.home;

  return (
    <Wrapper>
      <Container>
        <BellIcon className="mx-auto" />
        <h2 className="mt-4 text-center">{t('header')}</h2>
        <div className="mt-4 text-center website-text">{t('text')}</div>
        <ButtonContainer>
          <CustomButton
            className="mt-4 py-3 h-fit mx-auto"
            title={(
              <h4 className="bold white">{t('button')}</h4>
            )}
            onClick={navigateHome}
          />
        </ButtonContainer>
      </Container>
    </Wrapper>
  );
}

const Wrapper = tw.div`flex flex-col justify-center h-screen`;
const Container = tw.div`flex flex-col w-4/5 mx-auto`;
const ButtonContainer = tw.div`flex w-2/3 mx-auto`;
