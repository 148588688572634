export default {
  header: 'Table {{table}}',
  labels: {
    members_one: 'Single person',
    members_other: '{{count}} people in this group',
    left_to_pay: 'left to pay',
    total: 'total order',
    cancelled: 'Cancelled',
    refunded: '{{amount}} refunded',
  },
  tables: {
    payments: {
      title: 'Paid',
      headers: {
        time: 'time',
        person: 'Person',
        tip: 'tip',
        tax: 'taxes',
        method: 'method',
        total: 'total',
      },
    },
    sub_orders: {
      title: 'Orders',
    },
    cancelled_sub_orders: {
      title: 'Canceled orders',
    },
  },
  toasts: {
    failed_to_print: 'Failed to print, please try again',
    print_one: 'Printing {{count}} receipt: Order <strong>#{{orderNumber}}</strong>...',
    print_other: 'Printing {{count}} receipts: Order <strong>#{{orderNumber}}</strong>...',
    order_cancelled: 'Cancelled: Order <strong>#{{orderNumber}}</strong>...',
    refund_successful: 'Refund successful: Order <strong>#{{orderNumber}}</strong>...',
  },
  buttons: {
    refund: 'Refund',
    print: 'Print',
    cancel: 'Cancel',
  },
};
