import {
  CModal, CModalBody, CModalFooter,
} from '@coreui/react';
import React from 'react';
import CustomButton, { Types } from '../buttons/CustomButton';

export default function ConfirmationModal({
  show,
  title,
  description,
  confirmationButtonText,
  cancelButtonText,
  onConfirm,
  onCancel,
  confirming,
  cancelling,
}) {
  return (
    <CModal visible={show} alignment="center">
      <CModalBody>
        <h6>{title}</h6>
        {description ? (
          <p>{description}</p>
        ) : null}
      </CModalBody>
      <CModalFooter>
        <CustomButton
          title={cancelButtonText}
          onClick={onCancel}
          loading={cancelling}
          className="me-3"
          type={Types.transparent}
        />
        <CustomButton
          title={confirmationButtonText}
          onClick={onConfirm}
          loading={confirming}
          className="h-100 py-2"
        />
      </CModalFooter>
    </CModal>
  );
}
