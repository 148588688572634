import { ORDER_METHODS } from '../../../../../../../services/exports/Constants';

export default {
  title: {
    [ORDER_METHODS.pickup]: 'Vorbereitungszeit',
    [ORDER_METHODS.delivery]: 'Lieferzeit',
    [ORDER_METHODS.room_service]: 'Vorbereitungszeit',
    [ORDER_METHODS.dine_in]: 'Vorbereitungszeit',
  },
  text: '{{method}} in {{time}} Min schaffst du das?',
  text_scheduled: '{{method}} {{day}} am {{time}} schaffst du das?',
  labels: {
    today: 'Heute',
  },
  buttons: {
    confirm: 'Bestätigen',
  },
};
