export default {
  labels: {
    dummy_order: 'Test order',
    scheduled_for: 'Scheduled for Today, {{time}}',
    product_one: 'Product',
    product_other: 'Products',
  },
  buttons: {
    complete_order: 'Mark as complete',
  },
};
