export default {
  headers: {
    table_number: 'Table #',
    last_edited: 'Last edited',
    closing_time: 'Closing time',
    status: 'Status',
    details: 'Details',
  },
  status: {
    paid: 'Paid',
    refunded: 'Refunded',
  },
  empty_list: 'No closed tables right now',
};
