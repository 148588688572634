import { DeliveryProvider, ORDER_METHODS } from '../../../../../services/exports/Constants';

export default {
  labels: {
    dummy_order: 'Test Bestellung',
    scheduled_for: 'Geplant für heute, {{time}}',
    product_one: 'Produkt',
    product_other: 'Produkte',
  },
  buttons: {
    complete_order: 'Als erledigt markieren',
  },
};
