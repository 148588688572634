import { useTranslation } from 'react-i18next';
import {
  CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle,
} from '@coreui/react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import collect from 'collect.js';
import CustomButton, { Types } from '../buttons/CustomButton';
import useFeedbackHandler from '../../hooks/useFeedbackHandler';
import { DeliveryProvider, ORDER_REJECTION_REASONS } from '../../services/exports/Constants';
import OrdersManager from '../../services/api/OrdersManager';
import ChevronLeftIcon from '../../resources/assets/icons/ChevronLeftIcon';

export default function RejectOrderModal({
  toggle,
  order,
  onReject,
}) {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Modals:RejectOrderModal' });

  const { company } = useSelector((state) => state.company);

  const [selectedReason, setSelectedReason] = useState(null);

  const [loading, setLoading] = useState(false);
  const { setFeedback, renderFeedbackLabel } = useFeedbackHandler();

  if (!order) {
    return null;
  }

  const rejectOrder = async () => {
    setLoading(true);
    const { success, data } = await OrdersManager.reject(order.id, {
      reason: selectedReason,
    });
    setLoading(false);

    if (!success) {
      return setFeedback({
        message: data?.message,
        type: 'error',
        errors: data?.errors,
      });
    }

    toggle();

    return onReject(order);
  };

  const reasons = collect([
    ORDER_REJECTION_REASONS.ItemUnavailable,
    ORDER_REJECTION_REASONS.ClosingSoon,
    ORDER_REJECTION_REASONS.StoryBusy,
    ORDER_REJECTION_REASONS.CustomerMistake,
  ]).when(
    company?.delivery_provider !== DeliveryProvider.Standalone,
    (collection) => collection.push(ORDER_REJECTION_REASONS.DriverNotArrived),
  );

  return (
    <CModal
      id="multi-step-order-confirmation"
      visible
      alignment="center"
      scrollable
      size="xl"
      fullscreen
      onClose={() => toggle()}
    >
      <CModalHeader closeButton={false}>
        <button
          className="tw-h-8 tw-w-8 tw-aspect-square tw-rounded-full bg-black tw-flex tw-justify-center tw-border-none"
          onClick={() => toggle()}
        >
          <ChevronLeftIcon className="tw-h-3 tw-w-3 my-auto" color="white" />
        </button>
        <CModalTitle className="website-text bold">{t('header')}</CModalTitle>
      </CModalHeader>
      <CModalBody className="p-3">
        <h5 className="w-full text-center">{t('title')}</h5>
        <p className="mb-0 mt-2 w-full text-center">{t('text')}</p>
        <p className="mb-0 mt-2 w-full text-center">{order.user.name}</p>
        <p className="mb-0 mt-2 w-full text-center">{order.user.phone_number}</p>
        <div className="mt-3">
          {reasons.map((item) => (
            <label
              htmlFor={`order-rejection-reason-${item}`}
              className="mt-2 py-2 d-flex justify-content-between tw-border-b tw-border-t-none tw-border-x-none tw-border-solid tw-border-brand-inkGrey-grey_3 cursor-pointer"
              key={`order-rejection-reason-${item}`}
            >
              <p className="my-auto">{t(`reasons.${item}`)}</p>
              <input
                type="radio"
                checked={selectedReason === item}
                onChange={(e) => e.target.checked && setSelectedReason(item)}
                id={`order-rejection-reason-${item}`}
                className="tw-h-4 tw-w-4 my-auto"
              />
            </label>
          ))}
        </div>
        {renderFeedbackLabel}
      </CModalBody>
      <CModalFooter>
        <CustomButton
          type={Types.danger}
          title={t('buttons.confirm')}
          disabled={!selectedReason}
          onClick={rejectOrder}
          loading={loading}
          className="w-full"
        />
      </CModalFooter>
    </CModal>
  );
}
