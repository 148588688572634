import React from 'react';
import Lottie from 'react-lottie-player';
import classnames from 'classnames';
import LoadingIndicatorLight from '../../resources/assets/animations/LoadingIndicatorLight.json';
import LoadingIndicatorPrimary from '../../resources/assets/animations/LoadingIndicatorPrimary.json';

export const Types = {
  primary: 'primary',
  secondary: 'secondary',
  transparent: 'transparent',
  info: 'info',
  warning: 'warning',
  danger: 'danger',
};

export const Variants = {
  fill: 'fill',
  outline: 'outline',
};

export default function CustomButton({
  ref,
  title,
  onClick,
  className,
  style,
  type = Types.primary,
  variant = Variants.fill,
  loading = false,
  disabled = false,
}) {
  function renderLightLoadingIndicator() {
    return (
      <Lottie
        play
        loop
        animationData={type === 'primary' ? LoadingIndicatorLight : LoadingIndicatorPrimary}
        rendererSettings={{
          preserveAspectRatio: 'xMidYMid slice',
        }}
        style={{
          margin: 'auto',
          height: '25px',
          width: '50px',
        }}
      />
    );
  }

  const color = disabled ? 'gray-300' : ({
    [Types.primary]: 'brand-primary',
    [Types.secondary]: 'gray-100',
    [Types.transparent]: 'transparent',
    [Types.info]: 'black',
    [Types.warning]: 'brand-danger/30',
    [Types.danger]: 'brand-danger',
  })[type] ?? 'brand-primary';

  const textColor = disabled ? 'white' : ({
    [Types.primary]: 'white',
    [Types.secondary]: 'text-default',
    [Types.transparent]: 'text-default',
    [Types.info]: 'white',
    [Types.warning]: 'white',
    [Types.danger]: 'white',
  })[type] ?? 'text-default';

  const generalClasses = {
    'tw-rounded': true,
    [`tw-bg-${color} tw-text-${textColor} tw-shadow tw-border-none`]: variant === Variants.fill,
    [`tw-bg-white tw-text-${color} tw-border tw-border-solid tw-border-${color}`]: variant === Variants.outline,
  };

  const classMapping = ({
    [Types.primary]: {
      'tw-bg-gradient-to-r tw-from-gradient1-100 tw-to-gradient1-200': variant === Variants.fill,
    },
    [Types.secondary]: {
      '!tw-shadow-none': variant === Variants.fill,
    },
    [Types.transparent]: {
      '!tw-bg-transparent !tw-border-black !tw-text-black': variant === Variants.outline,
    },
    [Types.warning]: {
      '!tw-text-brand-danger': variant === Variants.fill,
    },
  })[type] ?? {};

  return (
    <button
      ref={ref}
      onClick={onClick}
      className={classnames(
        'tw-px-4 tw-h-10 tw-justify-center tw-items-center tw-text-base tw-sm:text-base sm:px-4 tw-xs:px-2',
        generalClasses,
        classMapping,
        className,
      )}
      style={style}
      disabled={disabled || loading}
    >
      {!loading ? title : renderLightLoadingIndicator()}
    </button>
  );
}
