export default {
  header: 'Active orders: {{count}}',
  subheaders: {
    scheduled_orders: 'Show scheduled orders ({{count}})',
    new_orders: 'New orders ({{count}})',
    ongoing_orders: 'Ongoing orders ({{count}})',
  },
  no_internet: {
    title: 'No network connection',
    text: 'You’re losing orders and money right now. Please check the internet. Try to turn the Wifi off and back on.',
    restored: 'You are back online',
  },
  error_messages: {
    failed_to_fetch_orders: 'Failed to load data. Please click on “refresh” icon or try again in 1 minute.',
    something_went_wrong: 'Something went wrong, please try again.',
  },
  toasts: {
    new_dine_in_order: 'Table {{table}}: {{lineItems}}',
  },
};
