import { createActions } from 'redux-actions';

export const actionCreators = createActions({
  authHelper: {
    setLoggedIn: undefined,
    setLoggedOut: undefined,
  },
  sidebar: {
    toggle: undefined,
  },
  appHeader: {
    set: undefined,
    reset: undefined,
  },
  eventHelper: {
    setEvent: undefined,
    resetEvent: undefined,
  },
  navigation: {
    setSelectedTab: undefined,
  },
  company: {
    set: undefined,
    reset: undefined,
    setPrintCopies: undefined,
    toggleAutoPrint: undefined,
    toggleDeliveryEnabled: undefined,
    toggleDineInEnabled: undefined,
  },
  activeOrders: {
    set: undefined,
  },
  orders: {
    setOrders: undefined,
    setNewOrders: undefined,
    setUnreviewedOrders: undefined,
    setScheduledOrders: undefined,
    setOngoingOrders: undefined,
  },
  print: {
    setInnerPrinterConnected: undefined,
  },
});
