export default {
  headers: {
    table_number: 'Table #',
    last_edited: 'Last edited',
    left_to_pay: 'Left to pay',
    total: 'Total',
    details: 'Details',
  },
  empty_list: 'No open tables right now',
};
