import { PAYMENT_METHODS } from '../../../../../services/exports/Constants';

export default {
  customer_info: {
    dummy_order: 'Test order',
    ordered_at: 'Ordered at:',
  },
  delivery_info: {
    info: 'Delivery info:',
    note: 'Remark:',
  },
  schedule_info: {
    pickup: 'Pickup',
    delivery: 'Delivery',
    room_service_location: {
      room_service: 'Room',
      table_service: 'Table',
    },
    wish_time: 'Desired time:',
    confirmed_time: 'Confirmed time:',
    driver_arrival_time: 'Driver pick-up time:',
    external_delivery: 'The order is delivered by an external driver from Uber',
    order_instructions: 'Order instructions:',
  },
  products: {
    item: 'Item:',
  },
  payment_breakdown: {
    price: 'Price:',
    subtotal: 'Subtotal',
    delivery_fee: 'Delivery fee',
    tips: 'Tip',
    processing_fee: 'Online service fee',
    payment_method_upcharge_fee: {
      [PAYMENT_METHODS.PAYPAL]: 'PayPal fee',
    },
    promo_applied: 'Promo applied',
    tax: 'Taxes',
    total: 'Total',
    paid_amount: 'Amount paid',
    order_not_paid: 'Order has not yet been paid',
    order_paid_online: 'Already paid online',
    cash: 'Payment cash',
    this_is_not_an_invoice: 'This is not an invoice',
  },
  delivery_qr_code: {
    scan: 'Scan & deliver with Google Maps',
  },
};
