import CloseOrder from './close-order';
import DineIn from './dine-in';
import OrderDetails from './order-details';
import RejectOrderModal from './RejectOrderModal';

export default {
  CloseOrder,
  DineIn,
  OrderDetails,
  RejectOrderModal,
};
