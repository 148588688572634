import React from 'react';
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalFooter,
} from '@coreui/react';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';
import CustomButton from '../../buttons/CustomButton';
import { ORDER_METHODS } from '../../../services/exports/Constants';

export default function CloseOrderModal({
  show = false,
  loading = false,
  showOrderDetailsModal = false,
  toggleModal,
  toggleOrderDetailsModal,
  order,
  onApprove,
}) {
  const { t } = useTranslation(null, { keyPrefix: 'Components:Modals:CloseOrder:CloseOrderModal' });

  if (!show || !order) {
    return null;
  }

  async function closeOrder() {
    onApprove(order);
    closeModal();
  }

  function closeModal() {
    if (showOrderDetailsModal) {
      toggleOrderDetailsModal();
    }

    toggleModal();
  }

  function renderButtons() {
    return (
      <CModalFooter className="justify-content-between border-none bg-white p-4">
        <ButtonsContainer>
          <div
            onClick={closeModal}
            className="website-text bold cursor-pointer d-flex"
          >
            <div className="my-auto">
              {t('buttons.cancel')}
            </div>
          </div>
          <CustomButton
            title={(
              <div className="website-text bold white">{t('buttons.confirm')}</div>
            )}
            onClick={closeOrder}
            loading={loading}
          />
        </ButtonsContainer>
      </CModalFooter>
    );
  }

  return (
    <CModal
      visible={show}
      alignment="center"
      scrollable
      size="lg"
      onClose={() => (show ? closeModal() : null)}
    >
      <CModalHeader
        closeButton={false}
        className="border-none p-4"
      >
        <CModalTitle>
          <h5>
            {order.method === ORDER_METHODS.delivery ? t(`text.${order.method}.${order.delivery_provider}`) : t(`text.${order.method}`)}
          </h5>
        </CModalTitle>
      </CModalHeader>
      {renderButtons()}
    </CModal>
  );
}

const ButtonsContainer = tw.div`grid grid grid-cols-2 gap-8 mx-auto`;
