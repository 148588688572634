import { PAYMENT_METHODS } from '../../../../../services/exports/Constants';

export default {
  customer_info: {
    dummy_order: 'Test Bestellung',
    ordered_at: 'Bestellt um:',
  },
  delivery_info: {
    info: 'Lieferanweisung:',
    note: 'Bemerkung:',
  },
  schedule_info: {
    pickup: 'Abholung',
    delivery: 'Lieferung',
    room_service_location: {
      room_service: 'Zimmer',
      table_service: 'Tisch',
    },
    wish_time: 'Gewünschte Zeit:',
    confirmed_time: 'Bestätigte Zeit:',
    driver_arrival_time: 'Fahrer Abholzeit:',
    external_delivery: 'Die Bestellung wird durch einen externen Fahrer von Uber ausgeliefert',
    order_instructions: 'Bestellanweisungen:',
  },
  products: {
    item: 'Artikel:',
  },
  payment_breakdown: {
    price: 'Preis:',
    subtotal: 'Zwischensumme',
    delivery_fee: 'Liefergebühr',
    tips: 'Trinkgeld',
    processing_fee: 'Onlinekostenzuschlag',
    payment_method_upcharge_fee: {
      [PAYMENT_METHODS.PAYPAL]: 'PayPal Gebühr',
    },
    promo_applied: 'Promo angewandt',
    tax: 'Taxes',
    total: 'Gesamt',
    paid_amount: 'Gezahlter Betrag',
    order_not_paid: 'Bestellung wurde noch nicht bezahlt',
    order_paid_online: 'Bereits online bezahlt',
    cash: 'Bezahlung Bar',
    this_is_not_an_invoice: 'Dies ist keine Rechnung',
  },
  delivery_qr_code: {
    scan: 'Scannen & Liefern mit Google Maps',
  },
};
