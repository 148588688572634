import {
  ORDER_METHODS,
  PAYMENT_METHODS,
  REFUND_REASONS,
  ROOM_SERVICE_LOCATION_TYPES,
} from '../../../../../../services/exports/Constants';

export default {
  header: 'Bestellinformationen',
  sections: {
    driver_info: 'Fahrer-Info',
    order_info: 'Bestell-Informationen',
    price_info: 'Preis-Information',
    refunds: 'Erstattungen',
  },
  order_methods: {
    [ORDER_METHODS.pickup]: 'Abholung',
    [ORDER_METHODS.delivery]: 'Lieferung',
    [ROOM_SERVICE_LOCATION_TYPES.room_service]: 'Zimmerservice',
    [ROOM_SERVICE_LOCATION_TYPES.table_service]: 'Tischservice',
  },
  room_service_location_types: {
    [ROOM_SERVICE_LOCATION_TYPES.room_service]: {
      details_label: 'Zimmernummer',
    },
    [ROOM_SERVICE_LOCATION_TYPES.table_service]: {
      details_label: 'Tischnummer',
    },
  },
  labels: {
    dummy_order: 'Test Bestellung',
    asap: 'ASAP',
    driver_name: 'Name des Fahrers',
    driver_phone_number: 'Telefon des Fahrers',
    original: 'Original',
    delivery_fee: 'Liefergebühr',
    tip: 'Trinkgeld',
    service_fee: 'Verwaltungsgebühren',
    payment_method_upcharge_fee: {
      [PAYMENT_METHODS.PAYPAL]: 'PayPal Gebühr',
    },
    promo_applied: 'Werbeaktion angewendet',
    tax: 'Taxes',
    total: 'Gesamt',
    online_payment: 'Kunde hat bereits in der FoodAmigos-App bezahlt',
    cash_payment: 'Bezahlung Bar',
    old_order: 'Diese Bestellung wurde erfolgreich ausgeführt',
    pickup_time: 'Abholzeit',
    pickup_asap: 'Der Kunde hat um schnellstmögliche Abholung gebeten.',
    points_used: 'Diese Bestellung enthält eine eingelöste Treuepunkt-Prämie von einem loyalen Kunden.',
    order_instructions: 'Bestellanweisungen:',
    today: 'heute',
  },
  schedule: {
    header: 'Geplant für {{date}}, {{time}}',
    text: 'Wir werden Sie daran erinnern, wenn es an der Zeit ist, die Bestellung vorzubereiten.',
  },
  refund_reasons: {
    [REFUND_REASONS.RequestedByCustomer]: 'Vom Kunden gewünscht',
    [REFUND_REASONS.Reclamation]: 'Reklamation',
    [REFUND_REASONS.CommunicationError]: 'Kommunikationsfehler',
    [REFUND_REASONS.Other]: 'Andere',
    null: '',
  },
  toasts: {
    print_one: 'Drucken {{count}} Beleg: Bestell-Nr <strong>{{orderNumber}}</strong>...',
    print_other: 'Drucken {{count}} Belege: Bestell-Nr <strong>{{orderNumber}}</strong>...',
    refund_successful: 'Refund successful: Order <strong>#{{orderNumber}}</strong>...',
  },
  buttons: {
    print: 'Drucken',
    confirm: 'Als abgeholt markieren',
    reject: 'Ablehnen',
    refund: 'Erstatten',
  },
};
