import React, { useEffect } from 'react';
import './scss/style.scss';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';
import ProfileManager from './services/api/ProfileManager';
import ErrorView from './views/error';
import PusherProvider from './providers/PusherProvider';
import PrintProvider from './providers/PrintProvider';
import RNWebviewResolver from './services/resolvers/rnwebview/RNWebviewResolver';
import HelpersProvider from './providers/HelpersProvider';
import MetaInfo from './components/meta/MetaInfo';
import AuthProvider from './providers/AuthProvider';
import SideEffectsProvider from './providers/SideEffectsProvider';
import Router from './routes/Router';

export default function App() {
  const { company } = useSelector((state) => state.company);

  useEffect(() => {
    function handleEvent(message) {
      new RNWebviewResolver(message?.data).resolve();
    }

    document.addEventListener('message', handleEvent);

    return () => document.removeEventListener('message', handleEvent);
  }, []);

  useEffect(
    () => moment.tz.setDefault(company?.timezone),
    [company?.timezone],
  );

  const handleError = () => {
    ProfileManager.pauseOrders(moment().endOf('day').format());
  };

  return (
    <Sentry.ErrorBoundary
      fallback={<ErrorView />}
      onError={handleError}
    >
      <AuthProvider>
        <PusherProvider>
          <HelpersProvider>
            <SideEffectsProvider>
              <PrintProvider>
                <Router />
              </PrintProvider>
            </SideEffectsProvider>
          </HelpersProvider>
        </PusherProvider>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop
          pauseOnHover={false}
          className="px-1 z-max"
          toastClassName="my-2 rounded-lg"
        />
        <MetaInfo />
      </AuthProvider>
    </Sentry.ErrorBoundary>
  );
}
