export default {
  header: 'Tisch {{table}}',
  labels: {
    members_one: 'Einzelperson',
    members_other: '{{count}} Personen in Gruppe',
    left_to_pay: 'Noch zu zahlen',
    total: 'Gesamtbetrag',
    cancelled: 'Storniert',
    refunded: '{{amount}} rückerstattet',
  },
  tables: {
    payments: {
      title: 'Bezahlt',
      headers: {
        time: 'Zeit',
        person: 'Person',
        tip: 'Trinkgeld',
        tax: 'taxes',
        method: 'Methode',
        total: 'Gesamt',
      },
    },
    sub_orders: {
      title: 'Bestellungen',
    },
    cancelled_sub_orders: {
      title: 'Stornierte Bestellungen',
    },
  },
  toasts: {
    failed_to_print: 'Druckvorgang fehlgeschlagen, bitte erneut versuchen',
    print_one: 'Printing {{count}} receipt: Order <strong>#{{orderNumber}}</strong>...',
    print_other: 'Printing {{count}} receipts: Order <strong>#{{orderNumber}}</strong>...',
    order_cancelled: 'Cancelled: Order <strong>#{{orderNumber}}</strong>...',
    refund_successful: 'Refund successful: Order <strong>#{{orderNumber}}</strong>...',
  },
  buttons: {
    refund: 'Erstatten',
    print: 'Drucken',
    cancel: 'Abbrechen',
  },
};
