import Banners from './banners/index';
import Cards from './cards';
import DineIn from './dine-in';
import Flickers from './flickers';
import Form from './form';
import Modals from './modals';
import Navigation from './navigation';
import Pagination from './pagination';
import Print from './print/index';
import Header from './Header';
import Sidebar from './Sidebar';

export default {
  Banners,
  Cards,
  DineIn,
  Flickers,
  Form,
  Modals,
  Navigation,
  Pagination,
  Print,
  Header,
  Sidebar,
};
