import {
  ORDER_METHODS,
  ROOM_SERVICE_LOCATION_TYPES,
} from '../../../../../../../services/exports/Constants';

export default {
  order_methods: {
    [ORDER_METHODS.pickup]: 'Pickup',
    [ORDER_METHODS.delivery]: 'Delivery',
    [ROOM_SERVICE_LOCATION_TYPES.room_service]: 'Room service',
    [ROOM_SERVICE_LOCATION_TYPES.table_service]: 'Table service',
  },
};
