import { DeliveryProvider, ORDER_METHODS } from '../../../../../../services/exports/Constants';

export default {
  text: {
    [ORDER_METHODS.pickup]: 'Has the customer already picked up the order?',
    [ORDER_METHODS.room_service]: 'Has the customer already picked up the order?',
    [ORDER_METHODS.delivery]: {
      [DeliveryProvider.Standalone]: 'Has the the order been delivered already?',
      [DeliveryProvider.FirstDelivery]: 'Has the driver already picked up the order?',
    },
  },
  buttons: {
    cancel: 'No, Cancel',
    confirm: 'Yes',
  },
};
