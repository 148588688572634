import { ORDER_REJECTION_REASONS } from '../../../../../../services/exports/Constants';

export default {
  titles: {
    step_1: 'Sicher, dass du die Bestellung ablehnen willst?',
    step_2: 'Sicher, dass du die Bestellung ablehnen willst?',
  },
  labels: {
    table: 'Tisch {{table}}',
    order: 'Bestellung',
  },
  subtitle: 'Wenn es ein kleines Problem mit der Bestellung gibt, sprich bitte zuerst mit dem Kunden, um es zu lösen.',
  reasons: {
    [ORDER_REJECTION_REASONS.ClosingSoon]: {
      name: 'Bald geschlossen',
      description: 'Leider haben wir keine Zeit mehr, die Bestellung vor Dienstschluss zu erledigen.',
    },
    [ORDER_REJECTION_REASONS.ItemUnavailable]: {
      name: 'Artikel nicht verfügbar',
      description: 'Leider können wir den/die von dir bestellten Artikel derzeit nicht anbieten.',
    },
    [ORDER_REJECTION_REASONS.CustomerMistake]: {
      name: 'Kundenfehler',
      description: 'Offenbar gab es ein Missverständnis.',
    },
  },
  buttons: {
    reject: 'Bestellung stornieren',
    back: 'Zurück',
    no_cancel: 'Nein, abbrechen',
    yes_reject: 'Ja, stornieren',
  },
};
